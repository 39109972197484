.customOverlay {
}
.customModal {
  max-width: 100%;
  width: 70%;
  border-radius: 5px;
  margin: 0;
}

.modal-heading {
  font-size: 2rem;
  font-family: var(--secondary_font);
  color: rgb(83, 83, 83);
}

.modal-description {
  font-size: 1rem;
  font-weight: 500;
  color: black;
  text-align: justify;
}

@media only screen and (max-width: 845px) {
  .customModal {
    width: 500px;
    border-radius: 5px;
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  .customModal {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: 0;
  }
  .react-responsive-modal-modal {
    margin: 10rem 0 0 !important;
    height: 100%;
  }
}
