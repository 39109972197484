.top {
  box-shadow: 0 0 5px 1px rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.05);
  position: absolute;
  z-index: 1;
  width: 100%;
}

.top_nav {
  padding: 1rem 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_nav-logo {
  max-width: 200px;
}

.top_nav-logo img {
  width: 100%;
  object-fit: fill;
}

.top .top_nav .top_nav-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top .top_nav .top_nav-navbar a {
  font-family: var(--secondary_font);
  /* font-weight: bold; */
  font-size: 1.5rem;
  text-decoration: none;
  margin: 0 0.8rem;
  color: var(--primary_color);
  position: relative;
  z-index: 1;
  transition: 300ms ease-in-out;
}

.top .top_nav .top_nav-navbar a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid red;
  height: 35px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 300ms ease-in-out;
}

.top .top_nav .top_nav-navbar a:hover::before,
.top .top_nav .top_nav-navbar a:focus::before {
  transform: scaleX(1);
}

.top .top_nav .top_nav-navbar a:hover,
.top .top_nav .top_nav-navbar a:focus {
  color: #00c661;
}

@media only screen and (max-width: 650px) {
  .top_nav {
    padding: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .top .top_nav .top_nav-navbar a {
    font-family: var(--primary_font);
    font-weight: bold;
    font-size: 0.8rem;
    text-decoration: none;
    margin: 0 0.2rem;
  }

  .top .top_nav .top_nav-navbar a::before {
    height: 25px;
  }

  .top_nav-logo {
    max-width: 150px;
  }
}