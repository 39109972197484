.copyright{
    background-color: #00c661;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0;
}

.copyright h1{
    color: white;
    font-family: var(--primary_font);
    font-size: 1rem;
    margin: 0.3rem 0 0;
    font-weight: bold;
}

@media only screen and (max-width: 550px) {
    .copyright h1{
        font-size: 0.8rem;
        text-align: center;
    }
  }