.homepage {
  max-width: 100%;
  position: relative;
  /* position: relative; */
}

.homepage img {
  width: 100%;
  height: 96vh;
  object-fit: cover;
  transform: scaleX(-1);
}

.homepage a img {
  width: 55px;
  height: 55px;
  border: 1px solid rgb(59, 59, 59, 0.7);
  border-radius: 50%;
  background-color: var(--primary_color) !important;
  position: absolute;
  left: 80%;
  bottom: -25px;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}

.homepage-overlay {
  position: absolute;
  width: 100%;
  height: 96vh;
  background-color: rgb(0, 0, 0, 0.7);
  top: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.homepage-overlay .outer {
  color: white;
  /* text-align: center; */
  font-family: var(--secondary_font);
  padding-left: 4rem;
}

.homepage-overlay .outer h1 {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 4rem;
  position: relative;
  animation: slideHeading_1 1s ease-in-out;
  margin: 0;
}

.homepage-overlay .outer h2 {
  margin: 1rem 0 0;
  font-family: var(--secondary_font);
  font-size: 2rem;
  animation: slideHeading_2 1.5s ease-in-out;
}

.company_name {
  color: #00c661;
}

/* .homepage-overlay .outer h2 .metal{
    animation: color_float 5s infinite ease-in-out;
} */

@keyframes slideHeading_1 {
  0% {
    transform: translateY(-800px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideHeading_2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 950px) {
  .homepage-overlay .outer {
    padding-left: 2rem;
  }

  .homepage-overlay .outer h1 {
    font-size: 2.5rem;
  }

  .homepage-overlay .outer h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 450px) {
  .homepage-overlay .outer h1 {
    font-size: 1.8rem;
  }

  .homepage-overlay .outer h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 380px) {
  .homepage-overlay{
    justify-content: center;
  }

  .homepage-overlay .outer  {
    padding-left: unset;
    text-align: center;
  }

  .homepage-overlay .outer h1 {
    font-size: 1.5rem;
    margin: 1rem 0 0;
  }

  .homepage-overlay .outer h2 {
    font-size: 1rem;
  }
}