.about-wrapper {
  padding: 0 6rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  padding: 0 1rem;
  /* border: 1px solid red; */
}

.about-container img {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  border: 2px solid black;
  padding: 0.1rem;
  /* box-shadow: 1px 1px 10px 1px black; */
  transition: 200ms ease-in-out;
}

.about-container img:hover {
  transform: scale(1.05);
}

.about-container h1 {
  font-size: 1.8rem;
  font-family: var(--secondary_font);
  color: #00c661;
  margin: 1rem 0;
  /* white-space: nowrap; */
}

.about-container p {
  font-size: 1.2rem;
  font-family: var(--secondary_font);
  margin: 1rem 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.about-container button {
  font-size: 1.5rem;
  font-family: var(--secondary_font);
  color: rgb(83, 83, 83);
  padding: 0;
  position: relative;
  cursor: pointer;
  z-index: 1;
  border: none;
  background-color: transparent;
  transition: transform 500ms ease-in-out;
}

.about-container button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-bottom: 4px solid red;
  height: 35px;
  transform-origin: left;
  transition: transform 200ms ease-in-out;
  transform: scaleX(0);
}

.about-container button:hover::before, 
.about-container button:focus::before {
  transform: scaleX(1);
}

.about-container button:hover,
.about-container button:focus {
  transform: translateX(10px);
  color: #00c661;
}

@media only screen and (max-width: 990px) {
  .about-wrapper {
    padding: 0 4rem;
  }

  .about-container img {
    width: 100%;
    height: 200px;
  }

  .about-container div {
    padding: 0 2rem;
  }

  .about-container h1 {
    font-size: 1.5rem;
  }

  .about-container button {
    font-size: 1.5rem;
  }

  .about-container p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {

  .about-container img {
    width: 100%;
    height: 200px;
  }

  
  .about-container button {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 370px) {
  .about-wrapper {
    padding: 0 4rem;
  }

  .about-container img {
    height: 180px;
  }
}

@media only screen and (max-width: 370px) {
  .about-wrapper {
    padding: 0 4rem;
  }

  .about-container img {
    height: 200px;
  }
}