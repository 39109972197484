.separator {
  width: 100%;
  height: 30px;
  margin-bottom: 25px;
}

.green {
  background-color: #00c661;
}

.black {
  background-color: #1b1b1b;
}

.blue {
  background-color: #0066f9;
}
