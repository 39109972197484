@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bakbak+One&family=Roboto+Slab:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top {
  box-shadow: 0 0 5px 1px rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.05);
  position: absolute;
  z-index: 1;
  width: 100%;
}

.top_nav {
  padding: 1rem 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_nav-logo {
  max-width: 200px;
}

.top_nav-logo img {
  width: 100%;
  object-fit: fill;
}

.top .top_nav .top_nav-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top .top_nav .top_nav-navbar a {
  font-family: var(--secondary_font);
  /* font-weight: bold; */
  font-size: 1.5rem;
  text-decoration: none;
  margin: 0 0.8rem;
  color: var(--primary_color);
  position: relative;
  z-index: 1;
  transition: 300ms ease-in-out;
}

.top .top_nav .top_nav-navbar a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 3px solid red;
  height: 35px;
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 300ms ease-in-out;
}

.top .top_nav .top_nav-navbar a:hover::before,
.top .top_nav .top_nav-navbar a:focus::before {
  transform: scaleX(1);
}

.top .top_nav .top_nav-navbar a:hover,
.top .top_nav .top_nav-navbar a:focus {
  color: #00c661;
}

@media only screen and (max-width: 650px) {
  .top_nav {
    padding: 1rem;
  }
}

@media only screen and (max-width: 450px) {
  .top .top_nav .top_nav-navbar a {
    font-family: var(--primary_font);
    font-weight: bold;
    font-size: 0.8rem;
    text-decoration: none;
    margin: 0 0.2rem;
  }

  .top .top_nav .top_nav-navbar a::before {
    height: 25px;
  }

  .top_nav-logo {
    max-width: 150px;
  }
}
.homepage {
  max-width: 100%;
  position: relative;
  /* position: relative; */
}

.homepage img {
  width: 100%;
  height: 96vh;
  object-fit: cover;
  transform: scaleX(-1);
}

.homepage a img {
  width: 55px;
  height: 55px;
  border: 1px solid rgb(59, 59, 59, 0.7);
  border-radius: 50%;
  background-color: var(--primary_color) !important;
  position: absolute;
  left: 80%;
  bottom: -25px;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}

.homepage-overlay {
  position: absolute;
  width: 100%;
  height: 96vh;
  background-color: rgb(0, 0, 0, 0.7);
  top: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.homepage-overlay .outer {
  color: white;
  /* text-align: center; */
  font-family: var(--secondary_font);
  padding-left: 4rem;
}

.homepage-overlay .outer h1 {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 4rem;
  position: relative;
  animation: slideHeading_1 1s ease-in-out;
  margin: 0;
}

.homepage-overlay .outer h2 {
  margin: 1rem 0 0;
  font-family: var(--secondary_font);
  font-size: 2rem;
  animation: slideHeading_2 1.5s ease-in-out;
}

.company_name {
  color: #00c661;
}

/* .homepage-overlay .outer h2 .metal{
    animation: color_float 5s infinite ease-in-out;
} */

@keyframes slideHeading_1 {
  0% {
    transform: translateY(-800px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideHeading_2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 950px) {
  .homepage-overlay .outer {
    padding-left: 2rem;
  }

  .homepage-overlay .outer h1 {
    font-size: 2.5rem;
  }

  .homepage-overlay .outer h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 450px) {
  .homepage-overlay .outer h1 {
    font-size: 1.8rem;
  }

  .homepage-overlay .outer h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 380px) {
  .homepage-overlay{
    justify-content: center;
  }

  .homepage-overlay .outer  {
    padding-left: unset;
    text-align: center;
  }

  .homepage-overlay .outer h1 {
    font-size: 1.5rem;
    margin: 1rem 0 0;
  }

  .homepage-overlay .outer h2 {
    font-size: 1rem;
  }
}
.about-wrapper {
  padding: 0 6rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
  padding: 0 1rem;
  /* border: 1px solid red; */
}

.about-container img {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  border: 2px solid black;
  padding: 0.1rem;
  /* box-shadow: 1px 1px 10px 1px black; */
  transition: 200ms ease-in-out;
}

.about-container img:hover {
  transform: scale(1.05);
}

.about-container h1 {
  font-size: 1.8rem;
  font-family: var(--secondary_font);
  color: #00c661;
  margin: 1rem 0;
  /* white-space: nowrap; */
}

.about-container p {
  font-size: 1.2rem;
  font-family: var(--secondary_font);
  margin: 1rem 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.about-container button {
  font-size: 1.5rem;
  font-family: var(--secondary_font);
  color: rgb(83, 83, 83);
  padding: 0;
  position: relative;
  cursor: pointer;
  z-index: 1;
  border: none;
  background-color: transparent;
  transition: transform 500ms ease-in-out;
}

.about-container button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-bottom: 4px solid red;
  height: 35px;
  transform-origin: left;
  transition: transform 200ms ease-in-out;
  transform: scaleX(0);
}

.about-container button:hover::before, 
.about-container button:focus::before {
  transform: scaleX(1);
}

.about-container button:hover,
.about-container button:focus {
  transform: translateX(10px);
  color: #00c661;
}

@media only screen and (max-width: 990px) {
  .about-wrapper {
    padding: 0 4rem;
  }

  .about-container img {
    width: 100%;
    height: 200px;
  }

  .about-container div {
    padding: 0 2rem;
  }

  .about-container h1 {
    font-size: 1.5rem;
  }

  .about-container button {
    font-size: 1.5rem;
  }

  .about-container p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {

  .about-container img {
    width: 100%;
    height: 200px;
  }

  
  .about-container button {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 370px) {
  .about-wrapper {
    padding: 0 4rem;
  }

  .about-container img {
    height: 180px;
  }
}

@media only screen and (max-width: 370px) {
  .about-wrapper {
    padding: 0 4rem;
  }

  .about-container img {
    height: 200px;
  }
}
.contact {
    width: 100%;
    padding-bottom: 5rem;
}

.contact-heading {
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
}

.contact .container .row {
    padding: 1rem 2rem 3rem;
}

.contact .container .row>div:last-child>h1 {
    padding: 5rem 5rem 0;
    text-align: start;
    font-size: 2rem;
    font-family: var(--secondary_font);
    color: #00c661;
}

.contact .container .row>div:last-child>p {
    padding: 0 5rem;
    text-align: start;
    font-size: 1.2rem;
    font-family: var(--primary_font);
    font-weight: 600;
}

.contact .container .row .contact-img {
    width: 80%;
    animation: floating 3s infinite ease-in-out;

}


.contact .container .row div:last-child {
    text-align: center;
    padding: 1rem;
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

.contact .container .row .contact-form .input-field {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 1rem;
}

.contact .container .row .contact-form .input-field label {
    font-size: 1.1rem;
    font-family: var(--primary_font);
    font-weight: bold;
    color: #00c661;
}

.contact .container .row .contact-form .send-btn {
    width: 100%;
    font-family: var(--secondary_font);
    font-size: 1.5rem;
    background-color: transparent;
    border: 2px solid #00c661;
    color: #00c661;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact .container .row .contact-form .send-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    bottom: 0;
    z-index: -1;
    background-color: #1b1b1b;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform .3s ease-in-out;
}

.contact .container .row .contact-form .send-btn:hover::before,
.contact .container .row .contact-form .send-btn:focus::before {
    transform: scaleY(1);
}

.contact .container .row .contact-form .send-btn:hover,
.contact .container .row .contact-form .send-btn:focus {
    color: white;
    border: none;

}

.contact .container .row .contact-form .submit_loader::after {
    position: absolute;
    content: '';
    z-index: -1;
    border: 8px solid #00c661;
    border-radius: 50%;
    border-top: 8px solid #1b1b1b;
    width: 35px;
    height: 35px;
    /* Safari */
    animation: spin 1.2s linear infinite;
}

/* Safari */

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 762px) {

    .contact .container .row {
        flex-direction: column-reverse;
    }

    .contact .container .row div:last-child {
        padding: 0 0 2rem;
    }

    .contact .container .row>div:last-child h1 {
        text-align: center;
        padding: 0;
    }

    .contact .container .row>div:last-child p {
        text-align: center;
        padding: 0;
    }
}
.copyright{
    background-color: #00c661;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0;
}

.copyright h1{
    color: white;
    font-family: var(--primary_font);
    font-size: 1rem;
    margin: 0.3rem 0 0;
    font-weight: bold;
}

@media only screen and (max-width: 550px) {
    .copyright h1{
        font-size: 0.8rem;
        text-align: center;
    }
  }
.separator {
  width: 100%;
  height: 30px;
  margin-bottom: 25px;
}

.green {
  background-color: #00c661;
}

.black {
  background-color: #1b1b1b;
}

.blue {
  background-color: #0066f9;
}

.heading{
    display: flex;
    justify-content: center;
    margin-top: -1.5rem;
    font-family: var(--secondary_font);
    color: #1b1b1b;
    align-items: center;
}

.heading h1{
    font-size: 3rem;
    margin: 2rem 1rem;
}

.heading hr{
    width: 100px;
    height: 2px;
}

@media only screen and (max-width: 450px) {
    .heading h1{
        font-size: 2rem;
        margin: 2rem 1rem;
    }
    .heading hr{
        width: 80px;
        height: 2px;
    }
  }


.customOverlay {
}
.customModal {
  max-width: 100%;
  width: 70%;
  border-radius: 5px;
  margin: 0;
}

.modal-heading {
  font-size: 2rem;
  font-family: var(--secondary_font);
  color: rgb(83, 83, 83);
}

.modal-description {
  font-size: 1rem;
  font-weight: 500;
  color: black;
  text-align: justify;
}

@media only screen and (max-width: 845px) {
  .customModal {
    width: 500px;
    border-radius: 5px;
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  .customModal {
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin: 0;
  }
  .react-responsive-modal-modal {
    margin: 10rem 0 0 !important;
    height: 100%;
  }
}


body, html, #root {
  height: 100%;
}

:root{
  --primary_color: white;
  --secondary_color: #b7b7b7;
  --tertiary_color: #848484;
  --tetra_color: #5a5a5a;
  --hex_color: #2e2e2e;
  --black: black;
  --primary_font: 'Roboto Slab', serif;
  --secondary_font: 'Bakbak One', cursive;

  --top_nav_color: "#01B2F7";
  --homepage_logo_color: "#0066f9"

}


